import type { NextPage } from "next";
import Head from "next/head";

import { useContext } from "react";
import AppContext from "../helper/context";

import { Content } from "../styles/styles";
import { Header, Footer, SideBar } from "../components";
import {
  Block01,
  Block02,
  Block03,
  Block04,
  Block05,
  Block06,
  Block07,
  Block08,
} from "../blocks";
import CustonSEO from "../components/CustonSEO";

const Home: NextPage = () => {
  const context = useContext(AppContext);
  return (
    <>
      <Content className={context?.sideBar ? "openSide" : ""}>
        <CustonSEO
          title="Cust.on - Custódia Inteligente de Criptoativos"
          description="Guarde suas criptos de forma segura e personalizada."
        />
        <Header />
        <Block01 />
        <Block02 />
        <Block03 />
        <Block04 />
        <Block05 />
        <Block06 />
        <Block07 />
        <Block08 />
        <Footer />
      </Content>
      <SideBar />
    </>
  );
};

export default Home;
